<template>
  <div
    id="faq"
    class="p-0 tw-flex tw-flex-1 tw-flex-row tw-w-full"
  >
    <faq-side
      class="flex-fixed"
      :questions="questions"
      :question="selectedQuestion"
    />
    <faq-content
      class="tw-flex-1"
      :question="question"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { EventBus } from '@/services/EventBus'

  import FaqSide from './components/FaqSide'
  import FaqContent from './components/FaqContent'

  import store from '@/store'

  import Config from '@/services/Config'

  const urls = {
    terms_of_service: `${Config.get('tos')}`,
    cmr_example: 'https://s3-eu-west-1.amazonaws.com/ct-documents/fr_chronotruck_delivery_order.pdf'
  }

  /**
   * @module view - faq
   */
  export default {
    name: 'Faq',
    metaInfo () {
      return {
        title: this.$trans('faq.title')
      }
    },
    components: {
      FaqSide,
      FaqContent
    },
    mounted () {
      EventBus.$on('faq:pick-question', (question) => {
        this.currentQuestion = question.slug
      })
    },
    beforeDestroy () {
      EventBus.$off('faq:pick-question')
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)
      next()
    },
    data () {
      return {
        currentQuestion: null
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      /**
       * Returns the question object, from the slug
       * @function questions
       * @returns {Array}
       */
      questions () {
        return [
          {
            slug: 'documents',
            title: this.$trans('faq.titles.carrier.documents_needed'),
            content: [
              this.$trans('faq.paragraphs.carrier.documents_needed'),
              this.$trans('faq.paragraphs.carrier.documents_needed.cmr_model', urls),
              this.$trans('faq.paragraphs.carrier.documents_needed.specific_doc'),
              this.$trans('faq.paragraphs.carrier.documents_needed.ask')
            ]
          },
          {
            slug: 'loads-prepared',
            title: this.$trans('faq.titles.loads_prepared'),
            content: [
              this.$trans('faq.paragraphs.carrier.loads_prepared'),
              this.$trans('faq.paragraphs.carrier.loads_prepared.loading'),
              this.$trans('faq.paragraphs.carrier.loads_prepared.loading_three_tons_more'),
              this.$trans('faq.paragraphs.carrier.loads_prepared.signal_reserve'),
              this.$trans('faq.paragraphs.carrier.loads_prepared.loading_directive'),
              this.$trans('faq.paragraphs.carrier.loads_prepared.important_doubt'),
              this.$trans('faq.paragraphs.carrier.loads_prepared.correspondig_merchandise')
            ]
          },
          {
            slug: 'goods-dispute',
            title: this.$trans('faq.titles.goods_dispute'),
            content: [
              this.$trans('faq.paragraphs.carrier.goods_dispute'),
              this.$trans('faq.paragraphs.carrier.goods_dispute.signal_problem'),
              this.$trans('faq.paragraphs.carrier.goods_dispute.merchandise_responsability'),
              this.$trans('faq.paragraphs.carrier.goods_dispute.case_provided_in_contract'),
              this.$trans('faq.paragraphs.carrier.goods_dispute.adjustment'),
              this.$trans('faq.paragraphs.carrier.goods_dispute.responsability')
            ]
          },
          {
            slug: 'responsibilities',
            title: this.$trans('faq.titles.responsibilities'),
            content: [
              this.$trans('faq.paragraphs.carrier.responsibilities'),
              this.$trans('faq.paragraphs.carrier.responsibilities.case_late_and_damage_goods', urls)
            ]
          },
          {
            slug: 'claims',
            title: this.$trans('faq.titles.claims'),
            content: [
              this.$trans('faq.paragraphs.carrier.claims', urls),
              this.$trans('faq.paragraphs.carrier.claims.reserve'),
              this.$trans('faq.paragraphs.carrier.claims.signal_reserve')
            ]
          }
        ]
      },
      selectedQuestion: {
        get () {
          return this.currentQuestion || this.questions[0].slug
        },
        set (question) {
          this.currentQuestion = question.slug
        }
      },
      /**
       * Returns the question object, from the slug
       * @function question
       * @returns {object}
       */
      question () {
        return this.questions.find(question => question.slug === this.selectedQuestion)
      }
    }
  }
</script>

<style lang="scss" scoped>

  #faq {
    height: calc(100vh - 55px);
    overflow-y: hidden;

    @media only screen and (max-width: 857px) {
      flex-direction: column;
      height: 100%;
      overflow-y: scroll !important;

      .faq-content {
        display: block;
        overflow-y: initial;
        padding-top: 16px;
        width: 100%;
        margin: 0;
      }
    }
  }

</style>
